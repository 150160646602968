import { http_client, http_client_with_status } from "../plugins/http_client";

const storeRecursoProveedor = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones`,
    params,
    "post"
  );
};

const getRecursosProveedorPorTipo = async (
  id_proceso_compra,
  id_tipo,
  params = {}
) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/${id_tipo}/proveedor`,
    params
  );
};

const getImpugnacionInstitucion = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/institucion`,
    params
  );
};

const getPronunciamiento = async (id_impugnacion, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/impugnaciones/${id_impugnacion}/pronunciamiento/`,
    params
  );
};

const getRecursosRevisionUcp = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/institucion`,
    params,
    "get"
  );
};

const getRecursosRevisionUcpAdmitidos = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/listar-aprobadas`,
    params,
    "get"
  );
};

const getComisionAltoNivel = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/comision/participante`,
    params,
    "get"
  );
};

const postParticipacionComision = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/comision/participante`,
    params,
    "post",
  );
};

const visualizarNombramientoComision = (idProcesoCompra, idNombramiento) => {
  return http_client_with_status(
    `/api/v1/compra/proceso/${idProcesoCompra}/impugnaciones/comision/visualizar-nombramiento/${idNombramiento}`,
    null, 
    'get',
    {},
    { responseType: 'blob' },
  );
};

const verificarParticipacionEnComision = (idProcesoCompra) => {
  return http_client_with_status(
    `/api/v1/compra/proceso/${idProcesoCompra}/impugnaciones/comision/verificacion-participacion`,
    null,
    'post',
  );
};

const getSeguimientos = async (id_estado_impugnacion, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/impugnaciones/tipo/${id_estado_impugnacion}`,
    params,
    "get"
  );
};

const putEstadoImpugnacion = (
  id_proceso_compra,
  id_impugnacion,
  body,
) => http_client_with_status(
  `/api/v1/compra/proceso/${id_proceso_compra}/impugnaciones/${id_impugnacion}`,
  body,
  "put",
  { 'Content-Type': 'multipart/form-data' },
);

const getEtapaSiguiente = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/etapa-siguente`,
    params,
    "get"
  );
};

const continuarProceso = async (id_proceso_compra, id_estado, params = {}) => {
  return await http_client(
    `/api/v1/compra/proceso/${id_proceso_compra}/cambio-estado/${id_estado}`,
    params,
    "put"
  );
};

const getTipoEtapaPrevia = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/etapas/validar-previa`,
    params,
    "get"
  );
};

const cargarResolucionFinal = (idProcesoCompra, params) => http_client_with_status(
  `/api/v1/compra/proceso/impugnaciones/cargar-resolucion-final/${idProcesoCompra}`,
  params, 
  'post', 
  { 'Content-Type': 'multipart/form-data' }
);

const eliminarResolucion = (idProcesoCompra, idDocumento) => http_client_with_status(
  `/api/v1/compra/proceso/impugnaciones/eliminar-resolucion-final/${idProcesoCompra}/${idDocumento}`,
  null, 
  'delete', 
);

const obtenerProveedoresOferentes = (idProcesoCompra) => http_client_with_status(
  `/api/v1/compra/proceso/oferentes/${idProcesoCompra}`,
  null,
  'get',
)

export default {
  storeRecursoProveedor,
  getRecursosProveedorPorTipo,
  getImpugnacionInstitucion,
  getPronunciamiento,
  getRecursosRevisionUcp,
  getComisionAltoNivel,
  postParticipacionComision,
  visualizarNombramientoComision,
  verificarParticipacionEnComision,
  getSeguimientos,
  putEstadoImpugnacion,
  getEtapaSiguiente,
  continuarProceso,
  getTipoEtapaPrevia,
  cargarResolucionFinal,
  eliminarResolucion,
  getRecursosRevisionUcpAdmitidos,
  obtenerProveedoresOferentes,
};
