import { http_client, http_client_with_status } from "@/plugins/http_client";

const getCertificacionesFondo = async (params ={}) => {
    return await http_client("/api/v1/cdf",params,"get")
}

const getCertificacionFondo = async (id_fondo,filter={}) => {
    return await http_client(`/api/v1/cdf/certificacion/${id_fondo}`,filter,"get")
}

const getCertificacionFondoUnidad = async (id_fondo,filter={}) => {
    return await http_client(`/api/v1/cdf/certificacion/${id_fondo}/unidades`,filter,"get")
}

const putCertificacion = async (id_fondos_procesos_certificado, body) => {
    return await http_client(`/api/v1/cdf/certificacion/${id_fondos_procesos_certificado}`,body,"put")
}

const obtenerProcesosCatalogo = async (params = {}) => {
    return await http_client_with_status(`/api/v1/cdf/listado/procesos/catalogo`, params, "get");
}

const cifradosCatalogo = async (id_proceso, params = {}) => {
    return await http_client_with_status(`/api/v1/cdf/cifrados/${id_proceso}`, params, "get");
}


const obtenerFinanciamientoUnidad = async (id_fondo, params = {}) => {
    return await http_client_with_status(`/api/v1/cdf/obtener/financiamiento/unidad/${id_fondo}`, params, "get");
}

const crearCerticacion = (data) => http_client_with_status('/api/v1/cdf/crear-certificacion', data, 'post', {
    'Content-Type': 'multipart/form-data',
  }); 

const obtenerTipoDocumento = () => http_client_with_status( `/api/v1/cdf/tipo/contrato`, null, "get");

const guardarDocumento = (id_contrato, params) => http_client_with_status(`api/v1/cdf/guardar-documento/${id_contrato}`, params, 'post', {'Content-Type': 'multipart/form-data'});

const verificarContratosProcesoCompra = (id_proceso) => http_client_with_status( `/api/v1/cdf/verificar-contrato/${id_proceso}`, null, "get");


export default {
    getCertificacionesFondo,
    getCertificacionFondo,
    getCertificacionFondoUnidad,
    putCertificacion,
    obtenerProcesosCatalogo,
    cifradosCatalogo,
    obtenerFinanciamientoUnidad,
    crearCerticacion,
    obtenerTipoDocumento,
    guardarDocumento,
    verificarContratosProcesoCompra,
}