import { http_client_slim } from "@/plugins/http_client";

const client = http_client_slim("/api/v1/administracion/correo");

const obtenerConfiguracionUsuario = async (id_usuario, data = {}) =>
  client(`/${id_usuario}`, { data });

const configurarMetodoCorreo = async (id_usuario, id_metodo, data = {}) =>
  client(`/${id_usuario}/${id_metodo}`, { data, method: "put" });

export default {
  obtenerConfiguracionUsuario,
  configurarMetodoCorreo,
};
