import procesoCompra from "@/services/PacProcesos.services";
import RecursoRevisionServices from "@/services/RecursoRevision.services";
import Vue from "vue";

export const getProcesoCompra = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.getProceso(payload.id_proceso_compra);
  if (response.status === 200)
    this.commit("procesoCompraDoc/setInfoProceso", response.data);
  Vue.prototype.hideLoader();
};

export const guardarDatosLote = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.storeLoteProceso(
    payload.id_proceso_compra,
    payload.body
  );
  if (response.status === 200) {
    this.dispatch("procesoCompraDoc/getLotes", {
      id_proceso_compra: payload.id_proceso_compra,
    });
    Vue.prototype.temporalAlert({
      show: true,
      type: "success",
      message: response.data.message,
    });
    this.commit("procesoCompraDoc/setShowModalAgregarLote", false);
  }
  Vue.prototype.hideLoader();
};

export const getLotes = async function ({ commit, state }, payload = {}) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.obtenerLotesConfigurados(
    payload.id_proceso_compra,
    {
      nombre: payload?.filters,
    }
  );
  if (response.status === 200) {
    commit("setLotes", response.data);
  }
  Vue.prototype.hideLoader();
};

export const getLote = async function ({ commit, state }, payload = {}) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.obtenerDatosLote(payload.id_lote);
  if (response.status === 200) {
    commit("setDatosLoteSeleccionado", response.data);
  }
  Vue.prototype.hideLoader();
};

export const actualizarDatosLote = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.actualizarDatosLote(payload.id_lote, {
    nombre: payload.nombre,
    descripcion: payload.descripcion,
  });
  if (response.status === 200) {
    this.dispatch("procesoCompraDoc/getLotes", {
      id_proceso_compra: payload.id_proceso_compra,
      filters: payload.filters,
    });
    Vue.prototype.temporalAlert({
      show: true,
      type: "success",
      message: response.data.message,
    });
  }
  Vue.prototype.hideLoader();
};
export const separarAgrupador = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.separarAgrupador(payload.id_lote);
  if (response.status === 200) {
    this.dispatch("procesoCompraDoc/getLotes", {
      id_proceso_compra: payload.id_proceso_compra,
      filters: payload.filters,
    });
    Vue.prototype.temporalAlert({
      show: true,
      type: "success",
      message: response.data.message,
    });
  }
  Vue.prototype.hideLoader();
};
export const separarEspecifica = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.separarEspecifica(payload.id_lote);
  if (response.status === 200) {
    this.dispatch("procesoCompraDoc/getLotes", {
      id_proceso_compra: payload.id_proceso_compra,
      filters: payload.filters,
    });
    Vue.prototype.temporalAlert({
      show: true,
      type: "success",
      message: response.data.message,
    });
  }
  Vue.prototype.hideLoader();
};

export const deleteLote = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.deleteLote(state.idLoteAccion);
  if (response.status === 200) {
    this.dispatch("procesoCompraDoc/getLotes", {
      id_proceso_compra: payload.id_proceso_compra,
      filters: payload.filters,
    });
    Vue.prototype.temporalAlert({
      show: true,
      type: "success",
      message: response.data.message,
    });
    commit("setDatosLoteSeleccionado", {
      nombre: null,
      descripcion: null,
    });
    commit("setShowModalDeleteLote", false);
    commit("setIdLoteAccion", null);
  }
  Vue.prototype.hideLoader();
};

export const storeProviderOffer = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.postProviderOffer(
    payload.id_proceso_compra,
    payload.form
  );
  Vue.prototype.hideLoader();

  return response;
};

export const getProviderOffers = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.getProviderOffers(
    payload.id_proceso_compra
  );
  if (response.status === 200) commit("setOffers", response.data);
  Vue.prototype.hideLoader();
};

export const getProviderOffer = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.getProviderOffer(
    payload.id_proceso_compra,
    payload.id_oferta,
    payload.id_participacion_proveedor
  );
  if (response.status === 200) commit("setOffer", response.data);
  Vue.prototype.hideLoader();
};

export const getProcessOffers = async function ({ commit, state }, payload) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.getProcessOffers(
    payload.id_proceso_compra,
    payload.query
  );
  if (response.status === 200) commit("setOffers", response.data);
  Vue.prototype.hideLoader();
};

export const guardarArchivoProceso = async function (
  { commit, state },
  payload
) {
  Vue.prototype.showLoader();
  let formData = new FormData();
  formData.append("documento", payload.documento);
  formData.append("nombre", payload.nombre);
  formData.append("id_proceso", payload.id_proceso_compra);
  formData.append("id_tipo_documento", payload.id_tipo_documento);
  formData.append("id_etapa_proceso", payload?.id_etapa_proceso);
  formData.append("folder", "generales");

  const response = await procesoCompra.guardarDocumentosProceso(formData);
  Vue.prototype.hideLoader();

  return response;
};

export const descargarArchivoProceso = async function (
  { commit, state },
  payload
) {
  const disk = payload.disk || undefined;

  let response = null;

  try {
    response = await procesoCompra.descargarDocumentoProceso({
      ruta: payload.ruta_documento,
      disk,
    });
  } catch (error) {
    response = await procesoCompra.descargarDocumentoProceso({
      ruta: payload.ruta_documento,
      disk: "local",
    });
  }
  if (!response) {
    Vue.prototype.temporalAlert({
      show: true,
      message: "Ocurrió un error al descargar el documento",
      type: "error",
    });
    return;
  }

  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });
  const file = new File([blob], "documento", {
    type: response.headers["content-type"],
  });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(file);
  link.download = "";
  link.click();
};

export const descargarBufferDocumento = async function (
  { commit, state },
  payload
) {
  Vue.prototype.showLoader();
  const response = await procesoCompra.getDocumentoProcesoBuffer({
    ruta: payload.ruta_documento,
  });

  if (!response) {
    Vue.prototype.temporalAlert({
      show: true,
      message: "Ocurrió un error al descargar el documento",
      type: "error",
    });
    Vue.prototype.hideLoader();
    return;
  }
  Vue.prototype.hideLoader();

  return response?.data;
};

export const getProveedorDocsPorTipo = async function (
  { commit, state },
  payload
) {
  Vue.prototype.showLoader();

  const response = await procesoCompra.obtenerDocumentosProcesoProvider(
    payload.id_proceso_compra,
    payload.id_tipo_documento
  );

  Vue.prototype.hideLoader();

  return response?.data;
};

export const getDocumentosProcesoPorTipo = async function (
  { commit, state },
  payload
) {
  Vue.prototype.showLoader();

  const response = await procesoCompra.obtenerDocumentosProceso(
    payload.id_proceso_compra,
    payload.id_tipo_documento,
    {
      id_etapa_proceso: payload?.id_etapa_proceso,
    }
  );

  Vue.prototype.hideLoader();

  return response?.data;
};

export const eliminarDocumentoProcesoPorId = async function (
  { commit, state },
  payload
) {
  Vue.prototype.showLoader();

  const response = await procesoCompra.deleteProcessDocument(payload.id);
  if (response?.status === 200) {
    Vue.prototype.temporalAlert({
      show: true,
      type: "success",
      message: "Se ha eliminado el documento seleccionado",
    });
  }

  Vue.prototype.hideLoader();
};

export const getEtapaActualProcesoImpugnacion = async function (
  { commit, state },
  payload
) {
  Vue.prototype.showLoader();

  const response = await procesoCompra.getEtapaActualProcesoImpugnacion(
    payload.id_proceso_compra
  );

  Vue.prototype.hideLoader();

  return response?.data;
};

export const getEtapasProcesoImpugnacion = async function (
  { commit, state },
  payload
) {
  Vue.prototype.showLoader();

  const response = await procesoCompra.getEtapasProcesoImpugnacion(
    payload.id_proceso_compra,
    payload.id_etapa_proceso
  );

  Vue.prototype.hideLoader();

  return response?.data;
}

export const cargarSiguientesEtapasImpugnacion = async ({ commit }, idProceso) => {
  commit("empezarPeticionSiguientesEtapasImpugnacion");
  const { data } = await RecursoRevisionServices.getEtapaSiguiente(idProceso);
  commit("manejarPeticionSiguientesEtapasImpugnacion", data);
}

export const cargarSiguientesEtapasImpugnacionConFiltro = async ({ commit }, { idProceso, filtros }) => {
  commit("empezarPeticionSiguientesEtapasImpugnacion");
  const { data } = await RecursoRevisionServices.getEtapaSiguiente(idProceso, filtros);
  commit("manejarPeticionSiguientesEtapasImpugnacion", data);
}

// Comision alto nivel
export const verificarParticipacionComisionAltoNivel = async ({ commit }, idProceso) => {
  if (Vue.prototype.haveRole('ROLE_PROVEEDOR')) return;

  commit('empezarPeticionVerificarParticipacionEnComision');
  const { data } = await RecursoRevisionServices.verificarParticipacionEnComision(idProceso);
  commit('manejarPeticionVerificarParticipacionEnComision', data);
}
