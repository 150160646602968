import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/eliminar/especificas/proceso${url}`, parametros, metodo);

const obtenerEspecificas = async (id_proceso_compra) =>
  http_client_slim(`/listar/${id_proceso_compra}/especificas`);

const obtenerInsumosEspecifica = async (id_solicitud_especifica) =>
  http_client_slim(`/insumos/${id_solicitud_especifica}/especifica-by-id`);

const eliminarEspecificasProceso = async (id_proceso_compra, body = {}) =>
  http_client_slim(`/solicitudes-especificas/${id_proceso_compra}`, body, 'put');

export default {
  obtenerEspecificas,
  obtenerInsumosEspecifica,
  eliminarEspecificasProceso,
};
