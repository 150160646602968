import { isApiErrorResponse } from "./loadable";
import { Observable } from "./observable";

export const interceptarErrorDeRed = (error) => {
    const mensajeError = error?.response?.data?.description;

    if (mensajeError && /etapa(.)*cerrada/ig.test(mensajeError)) {
        console.log('etapa cerrada.');
        Observable.emit('cierre-etapa-pip-general');
        Observable.emit('recargar-proceso-actual');
    }
}

export const interceptarErrorDeRedNuevoCliente = (response) => {
    if (isApiErrorResponse(response.data)) {
        const code = response.data.error.code;

        if (code === 'ETAPA_CERRADA')  {
            Observable.emit('cierre-etapa-pip-general');
            Observable.emit('recargar-proceso-actual');
        }
    }
}