const { http_client_with_status } = require("@/plugins/http_client");


const informacionCatalogo = (params = {}) => http_client_with_status(`api/v1/cm-herramienta/informacion`, params, 'get');
const ordenesCompraPorProveedor = (params = {}) => http_client_with_status(`api/v1/cm-herramienta/ordenes-compra-proveedor`, params, 'get');
const bitacoraProducto = (idProductoSolicitud, params = {}) => http_client_with_status(`api/v1/cm-herramienta/bitacora-producto/${idProductoSolicitud}`, params, 'get');
const bitacoraSolicitud = (idSolicitud, params = {}) => http_client_with_status(`api/v1/cm-herramienta/bitacora-solicitud/${idSolicitud}`, params, 'get');

export default {
    informacionCatalogo,
    ordenesCompraPorProveedor,
    bitacoraProducto,
    bitacoraSolicitud
};
