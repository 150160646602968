export class LocalStorage {

    static get token_procesos_libres() {
        return localStorage.getItem("token_procesos_libres");
    }

    static set token_procesos_libres(valor) {
        if (!valor) {
            localStorage.removeItem("token_procesos_libres");
        } else {
            localStorage.setItem("token_procesos_libres", valor);
        }
    }
}