import { http_client, http_client_with_status } from "@/plugins/http_client"
import {injectParameter} from "@/utils/text";

const getOpenRequest = async () => await http_client_with_status('/api/v1/cm-solicitudes-compra/carrito')

const getRequestById = async (requestId) => await http_client_with_status(`/api/v1/cm-solicitudes-compra/carrito/${requestId}`)

const createRequest = async (data) => await http_client_with_status('/api/v1/cm-solicitudes-compra/crear', data, 'POST')

const checkPriceChanges = async(requestId) => await http_client_with_status(injectParameter('/api/v1/cm-solicitudes-compra/validarSolicitud',requestId), null, 'POST');

const upsertProductToRequest = async(data,requestId) => await http_client_with_status(injectParameter('/api/v1/cm-solicitudes-compra/agregarProducto',requestId), data, 'POST');

const upsertProductToRequestEdit = async(data,requestId) => await http_client_with_status(injectParameter('/api/v1/cm-solicitudes-compra/agregarProducto/editar',requestId), data, 'POST');

const upsertServiceToRequest = async (data,requestId) => await http_client_with_status(injectParameter('/api/v1/cm-solicitudes-compra/agregarServicio',requestId), data, 'POST');

const upsertServiceToRequestEdit = async (data,requestId) => await http_client_with_status(injectParameter('/api/v1/cm-solicitudes-compra/agregarServicio/editar',requestId), data, 'POST');

const deleteOffertFromRequest = async(offerId,requestId) => await http_client_with_status(injectParameter(`/api/v1/cm-solicitudes-compra/eliminarProducto/${offerId}/${requestId}`), null, 'DELETE'); 

const sendRequestToApproval = async (requestId) => await http_client_with_status(injectParameter('/api/v1/cm-solicitudes-compra/enviarSolicitudAprobacion',requestId), null, 'POST')

const getRequestAmounts = async () => http_client_with_status('/api/v1/cm-solicitudes-compra/montos')

const getRequestTypes = async () => http_client_with_status('/api/v1/cm-solicitudes-compra/tiposSolicitud')

const markRequestAsClosed = async (requestId) => await http_client_with_status(`/api/v1/cm-solicitudes-compra/cerrarSolicitud/${requestId}`, null, 'PATCH');

const generarOrdenCompra = (requestId, data) => http_client_with_status(
    `/api/v1/cm-solicitudes-compra/generar-pdf/${requestId}`, 
    data, 
    'post', 
    { 'Content-Type': 'multipart/form-data' }, 
);

// const crearAgendamiento = async(requestId,data) => await http_client_with_status(`/api/v1/cm-solicitudes-compra/agendamiento/${requestId}`, data, 'POST', { 'Content-Type': 'multipart/form-data' });

const cargarInformacionOrdenCompra = (idSolicitud, idProveedor) => http_client_with_status(
    `/api/v1/cm-solicitudes-compra/cargar-informacion-pdf/${idSolicitud}/${idProveedor}`, 
    null, 
    'post',
);

const validarCantidadGasolina = (idOferta, params) => http_client(
    `/api/v1/cm-solicitudes-compra/calcular/gastos/administrativos/${idOferta}`, params, 'post',
);
const obtenerListaOfertasGastos = (idOferta, params) => http_client(
    `/api/v1/cm-solicitudes-compra/obtener-lista-ofertas/${idOferta}`, params, 'get',
);

const obtenerOrdenCompraCatalogo = (params = { }) => http_client(
    `/api/v1/cm-solicitudes-compra/descargar-orden-compra`, params,
    "get",
    {},
    true,
    "blob",
    true,
);



export default {
    getOpenRequest,
    createRequest,
    checkPriceChanges,
    deleteOffertFromRequest,
    sendRequestToApproval,
    getRequestAmounts,
    getRequestTypes,
    upsertProductToRequest,
    upsertServiceToRequest,
    markRequestAsClosed,
    getRequestById,
    upsertProductToRequestEdit,
    upsertServiceToRequestEdit,
    generarOrdenCompra,
    // crearAgendamiento,
    cargarInformacionOrdenCompra,
    validarCantidadGasolina,
    obtenerListaOfertasGastos,
    obtenerOrdenCompraCatalogo
};
