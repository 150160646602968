import { http_client_procesos_publicos } from "@/plugins/http_client_procesos_publicos";

const verificarDocumento = (payload) => http_client_procesos_publicos(
    `/api/v1/registro-ciudadanos/verificar-documento`, 
    payload, 
    "post",
    {},
    {},
);

const registrarUsuario = (payload) => http_client_procesos_publicos(
    `/api/v1/registro-ciudadanos/registro`, 
    payload, 
    "post",
    {},
    {},
);

const iniciarSesion = (payload) => http_client_procesos_publicos(
    `/api/v1/registro-ciudadanos/iniciar-sesion`, 
    payload, 
    "post",
    {},
    {},
);

const restablecerPassword = (payload) => http_client_procesos_publicos(
    `/api/v1/registro-ciudadanos/restablecer-password`,
    payload,
    "post",
    {},
    {},
);

const cambiarPassword = (payload) => http_client_procesos_publicos(
    `/api/v1/registro-ciudadanos/cambiar-password`,
    payload,
    "post",
    {},
    {},
);

export default {
    verificarDocumento,
    registrarUsuario,
    iniciarSesion,
    restablecerPassword,
    cambiarPassword,
}
