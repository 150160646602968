import Vue from "vue";
import Vuex from "vuex";
import jwtDecode from "jwt-decode";
import Usuarios from "@/services/Usuarios.services";
import ProveedoresServices from "../services/Proveedores.services";

// importacion de los modulos
import administracionObs from "../views/AdministracionObs/store/administracionObs";
import agregarInsumo from "../views/Solicitud/store/agregarInsumo";
import aprobacionProveedor from "../views/AprobacionProveedor/store/aprobacionProveedor";
import catalogo from "../views/Solicitud/store/catalogo";
import convenioMarcoAdmin from "../views/ConvenioMarco/store/convenioMarcoAdmin";
import convenioMarcoProveedor from "../views/ConveniosProveedor/store/convenioMarcoProveedor";
import proveedores from "../views/PerfilProveedor/store/proveedores";
import registroProveedor from "../views/RegistroProveedores/store/registroProveedor";
import shareholders from "../views/PerfilProveedor/store/shareholders";
import solicitudCompraJefeArea from "../views/JefeAreaAprobacion/store";
import usuariosModule from "../views/GestionUsuarios/store";
import techosPresupuestariosStore from "../views/TechosPresupuestarios/store";
import utils from "../modules/utils";
import comprasModule from "../views/Solicitud/store/solicitudCompra";
import solicitudCompra from "../views/Solicitud/store/solicitudCompra";
import solicitudCuenta from "../views/GestionUsuarios/store/solicitudCuenta";
import seguimientoProcesos from "@/views/seguimientoProcesos/store";

import estructuraPresupuestariaStore from "../views/TechosPresupuestarios/configuracionTechosPresupuestarios/store/estructuraPresupuestaria";
import lineaTrabajoStore from "../views/TechosPresupuestarios/configuracionTechosPresupuestarios/store/lineaTrabajo";
import fuenteFinanciamientoStore from "../views/TechosPresupuestarios/configuracionTechosPresupuestarios/store/fuenteFinanciamiento";
import organismoStore from "../views/TechosPresupuestarios/configuracionTechosPresupuestarios/store/organismoFinanciador";
import convenioStore from "../views/TechosPresupuestarios/configuracionTechosPresupuestarios/store/convenio";
import procesoCompra from "../views/PaacUaci/store/procesoCompra";
import paacCrearProcesoCompraStore from "../views/PaacUaci/store/crearProcesoCompra";
import etapaSubastaInversaStore from "../views/ProcesoCompraDoc/store/etapaSubastaInversaStore";
import paacProveedor from "../views/PaacProveedor/store";
import seguimientoOrden from "../views/ProcesoCompra/store/SeguimientoOrden";
import etapaConsultasStore from "../views/PaacUaci/store/etapaConsultasStore";
import etapaRecepcionOfertas from "../views/PaacProveedor/store/etapaRecepcionOfertas";
import etapaBaseProceso from "../views/PaacProveedor/store/etapaBaseProceso/";
import etapaRecepcionOfertasUaci from "../views/PaacUaci/store/etapaRecepcionOfertaStore/";
import etapaBaseProcesoUaci from "../views/PaacUaci/store/etapaBaseProcesoStore";
import etapaAperturaOfertaStore from "../views/PaacUaci/store/etapaAperturaOfertaStore";
import etapaResultadosPreliminares from "../views/PaacUaci/store/etapaResultadosPreliminares";
import procesoCompraUACI from "../views/PaacUaci/store/resumenProcesos";
import sancionesStore from "../views/SancionesProveedor/store";
import cifradosPresupuestarios from "../views/CifradosPresupuestarios/store";
import rupesStore from "../views/Rupes/store";
import pac from "../views/Pac/store";
import SolicitudCompraStore from "../views/SolicitudCompraModule/store";
import procesoCompraDoc from "../views/ProcesoCompraDoc/store";
import reporteProveedor from "../views/ReporteProveedores/store";
import ProcCompReservaStore from "../views/ProcesoCompraReserva/store";
import peoStore from "../views/peo/store";
import compraLinea from "../views/solicitudCompraLinea/store";
import fondoCertificado from "../views/Cdf/store";
import catalogoEnLineaStore from "../views/CatalogoEnLinea/store";
import cmConvenioStore from "../views/CMConvenio/store";
import cmSolicitudesConObservacion from "../views/CMSolicitudesConObservacion/store";
import cmAprobacionCompraElectronica from "@/views/CMProcesoAprobacionCompraElectronica/store";
import subastaInversaReporteria from "@/views/reporteriaDirector/subastaInversa/store";
import configuracionSalas from "@/views/ProcesoCompraDoc/store/configuracionSubasta";
import panelAdministracionOdcStore from '@/views/PanelAdministracionODC/store';
import SeguimientoContractual from '@/views/ProcesoCompra/SeguimientoContractual/store';
import cmReporteriaStore from "@/views/CmReporteria/store/index";
import recursoApelacionStore from "@/views/ProcesoCompraDoc/RecursoApelacion/store";

import compraExterior from '@/views/CompraExterior/store';
import AuthServices from "@/services/Auth.services";
import { createLoadable, setLoadableResponse, toggleLoadable, shouldFetch, isResponseSuccesful } from "@/utils/loadable";
import AsuetosServices from "@/services/Asuetos.services";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: {},
    anioFiscal: null,
    unidades: [],
    selectedUnidad: {},
    cargos: {
      jefe_uaci: 11,
      jefe_ufi: 4,
      jefe_unidad_solicitante: 6,
      super_admin: 1,
      tecnico_unidad: 9,
      tecnico_uaci: 3,
      tecnico_ufi: 5,
      director: 10,
      tecnico_unidad_consolidadora: 7,
    },
    notificaciones_pendientes: 0,
    notificaciones_list: [],
    verificacion_docs_proveedor: false,
    title: "COMPRASAL",
    sockets: {
      notificaciones: null,
      general: null,
      chats: null,
      procesos: null,
    },
    serverTime: null,
    institucionesUsuario: [],
    cargaDiasNoLaborables: createLoadable(null),
    asuetos: [],
    feriados: [],
  },
  getters: {
    showStats(state) {
      if (state.selectedUnidad?.cargo) {
        let cargo = state.selectedUnidad?.cargo[0]?.id;
        return (
          cargo == state.cargos.jefe_uaci ||
          cargo == state.cargos.jefe_ufi ||
          cargo == state.cargos.jefe_unidad_solicitante ||
          cargo == state.cargos.jefe_unidad_consolidadora
        );
      } else {
        return false;
      }
    },
    showSolicitudButton(state) {
      if (state.selectedUnidad?.cargo) {
        let cargo = state.selectedUnidad?.cargo[0]?.id;
        let tecnicoUnidad = [3, 5, 7, 9];
        let jefeUnidad = [4, 6, 11, 19];
        return tecnicoUnidad.includes(cargo) || jefeUnidad.includes(cargo);
      } else {
        return false;
      }
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setAnioFiscal(state, payload) {
      localStorage.setItem("anioFiscalSeleccionado", payload);
      state.anioFiscal = payload;
    },
    setUnidades(state, payload) {
      state.unidades = payload;
    },
    setSelectedUnidad(state, payload) {
      state.selectedUnidad = payload;
    },
    setNotificacionesPendientes(state, payload) {
      state.notificaciones_pendientes = payload;
    },
    setNotificaciones(state, payload) {
      state.notificaciones_list = payload;
    },
    setVerificacionDocsProveedor(state, payload) {
      state.verificacion_docs_proveedor = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setSocket(state, payload) {
      state.sockets[payload.socket] = payload.connection;
    },
    setServerTime(state, payload) {
      state.serverTime = payload;
    },
    setInstitucionesUsuario(state, payload) {
      state.institucionesUsuario = payload;
    },
    empezarPeticionDiasNoLaborables(state) {
      toggleLoadable(state.cargaDiasNoLaborables);
    },
    manejarRespuestaPeticionDiasNoLaborables(state, payload) {
      setLoadableResponse(state.cargaDiasNoLaborables, payload, { skipOnSuccess: true });

      if (isResponseSuccesful(payload)) {
        state.asuetos = payload.data.asuetos;
        state.feriados = payload.data.feriados.map((asueto) => moment(asueto.fecha, "YYYY-MM-DD"));
      } else {
        state.asuetos = [];
        state.feriados = [];
      }
    },
  },
  actions: {
    setAuth({ commit }, payload) {
      const { token, refreshToken, tokenID } = payload;
      commit("setUserInfo", jwtDecode(token));
      commit("setToken", token);

      localStorage.setItem("tokenID", tokenID);
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refreshToken);
    },
    getUnidades: async ({ commit, state }) => {
      if (state.unidades.length == 0) {
        const idUnidad = localStorage.getItem("idUnidad");
        try {
          const response = await Usuarios.getUnidadesUsuario();
          if (response) {
            commit("setUnidades", response?.data);

            if (idUnidad) {
              const unidad = state.unidades.find(
                (unidad) => unidad.id == idUnidad
              );
              commit("setSelectedUnidad", unidad);
            } else {
              commit("setSelectedUnidad", state.unidades[0]);
              localStorage.setItem("idUnidad", state.selectedUnidad?.id);
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
      }
    },
    validarSancionActivaProveedor: async ({ commit, state }, ruta) => {
      // Solamente validar las rutas de este arreglo porque es donde al proveedor se le permite participar
      const rutasConValidacion = [
        "proveedor-paac",
        "proceso-compra-paac",
        "ofertar-paac",
        "listado-convenios-proveedor",
        "detalle-convenios-proveedor",
        "ofertar-producto",
      ];

      if (rutasConValidacion.includes(ruta.name)) {
        Vue.prototype.showLoader();
        const response = await ProveedoresServices.sancionActivaProveedor();
        Vue.prototype.hideLoader();
        return response.data.length > 0;
      }

      return false;
    },
    verificarValidacionDocs: async ({ commit, state }, ruta) => {
      const response = await ProveedoresServices.verificarValidacionDocs();
      commit("setVerificacionDocsProveedor", response?.data?.debe_verificar);
      return response?.data?.debe_verificar;
    },
    cargarInstitucionesUsuario: async ({ commit, state }) => {
      const response = await AuthServices.cargarInstitucionesUsuario();
      commit('setInstitucionesUsuario', response.data ?? []);
    },
    cargarDiasNoLaborables: async ({ commit, state }, payload) => {
      if (!shouldFetch(state.cargaDiasNoLaborables) && !payload?.reintento) return;

      commit('empezarPeticionDiasNoLaborables');
      const params = {
        desde: moment().subtract(1, 'day').format("YYYY-MM-DD"),
        hasta: moment().add(1, 'year').format("YYYY-MM-DD"),
      };
      const { data } = await AsuetosServices.cargarDiasNoLaborables(params);
      commit('manejarRespuestaPeticionDiasNoLaborables', data);
    },
  },
  modules: {
    administracionObs,
    agregarInsumo,
    aprobacionProveedor,
    catalogo,
    convenioMarcoAdmin,
    convenioMarcoProveedor,
    proveedores,
    registroProveedor,
    shareholders,
    solicitudCompraJefeArea,
    usuariosModule,
    techosPresupuestariosStore,
    utils,
    comprasModule,
    solicitudCompra,
    estructuraPresupuestariaStore,
    lineaTrabajoStore,
    fuenteFinanciamientoStore,
    organismoStore,
    convenioStore,
    solicitudCuenta,
    procesoCompra,
    paacCrearProcesoCompraStore,
    etapaSubastaInversaStore,
    paacProveedor,
    seguimientoOrden,
    etapaConsultasStore,
    etapaRecepcionOfertas,
    etapaBaseProceso,
    etapaRecepcionOfertasUaci,
    etapaBaseProcesoUaci,
    etapaAperturaOfertaStore,
    etapaResultadosPreliminares,
    procesoCompraUACI,
    sancionesStore,
    cifradosPresupuestarios,
    rupesStore,
    pac,
    SolicitudCompraStore,
    procesoCompraDoc,
    reporteProveedor,
    ProcCompReservaStore,
    peoStore,
    compraLinea,
    fondoCertificado,
    catalogoEnLinea: catalogoEnLineaStore,
    cmConvenioStore,
    cmSolicitudesConObservacion,
    cmAprobacionCompraElectronica,
    subastaInversaReporteria,
    seguimientoProcesos,
    configuracionSalas,
    panelAdministracionOdc: panelAdministracionOdcStore,
    SeguimientoContractual,
    compraExterior,
    cmReporteria: cmReporteriaStore,
    recursoApelacion: recursoApelacionStore,
  },
});
