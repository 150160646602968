import { http_client_with_status } from "@/plugins/http_client";

const cargarResumenInstituciones = (filtros) => http_client_with_status(
    `/api/v1/reporteria-compra-en-linea/resumen-instituciones`,
    filtros,
    'get',
);

const cargarFondosInstitucionales = (idInstitucion, filtros) => http_client_with_status(
    `/api/v1/reporteria-compra-en-linea/fondos-institucionales/${idInstitucion}`,
    filtros,
    'get',
);

const cargarEstadisticasAnuales = (idAnio, filtros) => http_client_with_status(
    `/api/v1/reporteria-compra-en-linea/estadisticas-anuales/${idAnio}`,
    filtros,
    'get',
);

const cargarDocumentosRegistro = (idRegistro) => http_client_with_status(
    `/api/v1/reporteria-compra-en-linea/documentos-registro/${idRegistro}`,
    null,
    'get',
);

const visualizarDocumento = (idDocumento) => http_client_with_status(
    `/api/v1/reporteria-compra-en-linea/documentos-registro/visualizar/${idDocumento}`,
    null,
    'get',
    {}, 
    { responseType: 'blob' },
);

const cargarRegistrosPorFondo = (idFondo, params) => http_client_with_status(
    `/api/v1/reporteria-compra-en-linea/registros/${idFondo}`,
    params,
    'get',
);

export default {
    cargarResumenInstituciones,
    cargarFondosInstitucionales,
    cargarEstadisticasAnuales,
    cargarDocumentosRegistro,
    visualizarDocumento,
    cargarRegistrosPorFondo,
}
