import RecursoApelacionServices from "@/services/RecursoApelacion.services";
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from "@/utils/loadable";

const recursoApelacionStore = {
    namespaced: true,
    state: {
        etapaActual: createLoadable(null),
        etapas: createLoadable([]),
        actualizacionEtapa: createLoadable(null),
        verificacionPIPActivo: createLoadable(null),
        proveedoresRecursoApelacion: createLoadable([]),
        resoluciones: createLoadable([]),
    },
    mutations: {
        empezarPeticionEtapaActual(state) {
            toggleLoadable(state.etapaActual);
        },
        manejarRespuestaPeticionEtapaActual(state, payload) {
            setLoadableResponse(state.etapaActual, payload);
        },
        empezarPeticionEtapas(state) {
            toggleLoadable(state.etapas);
        },
        manejarRespuestaPeticionEtapas(state, payload) {
            setLoadableResponse(state.etapas, payload);
        },
        empezarPeticionActualizacionEtapa(state) {
            toggleLoadable(state.actualizacionEtapa);
        },
        manejarRespuestaPeticionActualizacionEtapa(state, payload) {
            setLoadableResponse(state.actualizacionEtapa, payload, { showAlertOnSuccess: true, skipOnSuccess: true });
        },
        empezarPeticionVerificacionPIPActivo(state, payload) {
            toggleLoadable(state.verificacionPIPActivo, payload);
        },
        manejarRespuestaPeticionVerificacionPIPActivo(state, payload) {
            setLoadableResponse(state.verificacionPIPActivo, payload);
        },
        empezarPeticionProveedoresApelacion(state) {
            toggleLoadable(state.proveedoresRecursoApelacion);
        },
        manejarRespuestaPeticionProveedoresApelacion(state, payload) {
            setLoadableResponse(state.proveedoresRecursoApelacion, payload);
        },
        empezarPeticionResoluciones(state) {
            toggleLoadable(state.resoluciones);
        },
        manejarRespuestaPeticionResoluciones(state, payload) {
            setLoadableResponse(state.resoluciones, payload);
        },
        limpiarResoluciones(state) {
            state.resoluciones = createLoadable([]);
        },
    },
    actions: {
        cargarEtapaActual: async ({ commit }, idProcesoCompra) => {
            commit('empezarPeticionEtapaActual');
            const { data } = await RecursoApelacionServices.cargarEtapaActiva(idProcesoCompra);
            commit('manejarRespuestaPeticionEtapaActual', data);
        },
        cargarEtapas: async ({ commit }, idProcesoCompra) => {
            commit('empezarPeticionEtapas');
            const { data } = await RecursoApelacionServices.cargarEtapasRecursoApelacion(idProcesoCompra);
            commit('manejarRespuestaPeticionEtapas', data);
        },
        actualizarEtapa: async({ commit }, payload) => {
            const { id_proceso_compra: idProcesoCompra, id_etapa_recurso_apelacion: idEtapaRecursoApelacion, data: fechas  } = payload;

            commit('empezarPeticionActualizacionEtapa');
            const { data } = await RecursoApelacionServices.actualizarEtapaRecursoApelacion(idProcesoCompra, idEtapaRecursoApelacion, fechas);
            commit('manejarRespuestaPeticionActualizacionEtapa', data);

            return isResponseSuccesful(data);
        },
        verificarPIPActivo: async({ commit }, idProcesoCompra) => {
            commit('empezarPeticionVerificacionPIPActivo');
            const { data } = await RecursoApelacionServices.verificarPIPActivo(idProcesoCompra);
            commit('manejarRespuestaPeticionVerificacionPIPActivo', data);
        },
        cargarProveedoresParaApelacion: async({ commit }, idProcesoCompra) => {
            commit('empezarPeticionProveedoresApelacion');
            const { data } = await RecursoApelacionServices.cargarProveedoresParaApelacion(idProcesoCompra);
            commit('manejarRespuestaPeticionProveedoresApelacion', data);
        },
        cargarResoluciones: async({ commit }, idProcesoCompra) => {
            commit('empezarPeticionResoluciones');
            const { data } = await RecursoApelacionServices.cargarResoluciones(idProcesoCompra);
            commit('manejarRespuestaPeticionResoluciones', data);
        },
    },
}

export default recursoApelacionStore;