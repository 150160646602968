import { http_client_with_status } from "@/plugins/http_client"

const crearPrevencion = (idRecurso, data) => http_client_with_status(`/api/v1/prevencion-recurso-revision/${idRecurso}`, data, 'post', { 
    'Content-Type': 'multipart/form-data',
});

const cargarRecursosConPrevencion = (idEtapaProceso) => http_client_with_status(`/api/v1/prevencion-recurso-revision/recursos-con-prevencion/${idEtapaProceso}`, null, 'get');

const visualizarPrevencion = (idPrevencion) => http_client_with_status(
    `/api/v1/prevencion-recurso-revision/prevencion/documento/${idPrevencion}`, 
    null, 
    'get',
    {},
    { responseType: 'blob' },
);

const subsanarPrevencion = (idPrevencion, data) => http_client_with_status(
    `/api/v1/prevencion-recurso-revision/prevencion/subsanar/${idPrevencion}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' }
);

const visualizarSubsanacionPrevencion = (idSubsanacion) => http_client_with_status(
    `/api/v1/prevencion-recurso-revision/prevencion/subsanacion/visualizar/${idSubsanacion}`,
    null,
    'get',
    {},
    { responseType: 'blob' },
);

const obtenerPrevencionRecursoRevision = (idPrevencion) => http_client_with_status(
    `/api/v1/prevencion-recurso-revision/prevencion/${idPrevencion}`,
    null,
    'get',
);

export default { 
    crearPrevencion, 
    cargarRecursosConPrevencion, 
    visualizarPrevencion,
    subsanarPrevencion,
    visualizarSubsanacionPrevencion,
    obtenerPrevencionRecursoRevision,
};
