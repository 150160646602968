import { http_client_with_status } from "@/plugins/http_client";

const cargarInstituciones = (filtros) => http_client_with_status(
    `/api/v1/reporteria-cierre-contratos-institucion/instituciones`,
    filtros,
    'get',
);

const cargarEstadisticas = (filtros) => http_client_with_status(
    `/api/v1/reporteria-cierre-contratos-institucion/estadisticas`,
    filtros,
    'get',
);

const cargarProcesos = (idInstitucion, filtros) => http_client_with_status(
    `/api/v1/reporteria-cierre-contratos-institucion/procesos-por-institucion/${idInstitucion}`,
    filtros,
    'get',
);

export default {
    cargarInstituciones,
    cargarEstadisticas,
    cargarProcesos,
}
