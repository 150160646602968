import { http_client } from "@/plugins/http_client";

// petición GET
const getListadoAdministracionContrato = async (params) => await http_client('/api/v1/verificacion', params);
const postInstitucion = async (data) => await http_client('/api/v1/verificacion', data, 'POST');

const getAnioFiscal = async (params) => await http_client('/api/v1/verificacion/obtener/anio-fiscal', params);

const eliminarInstitucion = async (id_institucion_anio_fiscal, params = {}) =>
    await http_client(`/api/v1/verificacion/${id_institucion_anio_fiscal}`, params, 'DELETE');

const listarInstituciones = async ( params ) =>
    await http_client('/api/v1/verificacion/listado-instituciones', params);
export default {
    getListadoAdministracionContrato,
    getAnioFiscal,
    eliminarInstitucion,
    listarInstituciones,
    postInstitucion,
}