const adminGeneracionContrato = [
    {
        path: '/administracion-generacion-contrato',
        name: 'administracion-generacion-contrato',
        component: () =>
            import(
                /* webpackChunkName: "administracion-obs" */ '../views/ListAdminGeneracionContrato.vue'
                )
    }
]

export default adminGeneracionContrato