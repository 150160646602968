<template>
  <v-dialog v-model="show" persistent max-width="700">
    <v-card class="pa-4" max-width="700">
      <div class="headerIcon" v-if="cornerClose">
        <v-icon color="secondary" @click="close"> mdi-close </v-icon>
      </div>
      <v-row>
        <v-col cols="12">
          <p class="text-h5 text-center px-4">
            {{ title }}
          </p>
        </v-col>
      </v-row>
      <v-card-text class="text-center">{{ message }}</v-card-text>
      <v-card-text v-if="showMotivoCancelar" class="text-center">
        <v-textarea
          ref="motivoCancelar"
          @input="handleInput"
          label="Motivo *"
          outlined
          row-height="30"
          rows="4"
          auto-grow
          clearable
          counter="500"
          maxlength="500"
          :rules="[validateMotivoCancelar]"
        />
      </v-card-text>
      <v-card-actions>
        <v-row class="display-flex justify-center">
          <v-col cols="12" sm="4">
            <v-btn
              block
              outlined
              color="primary darken-1"
              class="text-capitalize"
              @click="close"
              v-if="!showLoading"
            >
              {{ btnCancel }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              block
              color="secondary"
              class="white--text text-capitalize"
              @click="confirm"
              :loading="showLoading"
            >
              {{ btnConfirmar }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialogComponent",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    showMotivoCancelar: {
      type: Boolean,
      default: false,
    },
    btnConfirmar: {
      type: String,
      default: "Confirmar",
      required: false,
    },
    btnCancel: {
      type: String,
      default: "Cancelar",
      required: false,
    },
    cornerClose: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async confirm() {
      //   this.dialog = false;
      if (!this.callback) this.$emit("confirm");
      else {
        await this.callback();
        this.$emit("cerrar");
        this.$emit("close");
      }
    },
    handleInput(value) {
      this.$emit("input", value);
    },
    validateMotivoCancelar(value) {
      const regLetter = /^[A-Za-zÀ-ÿ ,;:.0-9\n$-?!]+$/;
      if (value) {
        if (value.length > 500) {
          return "El motivo no puede superar los 500 caracteres";
        } else {
          if (regLetter.test(value)) {
            return true;
          } else {
            return "El motivo no puede contener caracteres especiales";
          }
        }
      } else {
        return "Este campo es requerido.";
      }
    },
    verifyMotivoCancelar() {
      this.$refs.motivoCancelar.validate(true);
      return this.$refs.motivoCancelar.valid;
    },
  },
};
</script>
