import { http_client_with_status } from "@/plugins/http_client";

// Notificacion de resolucion de revision
const obtenerDocumentoNotificacionResolucionResultados = (idProcesoCompra, idEtapaProceso) => http_client_with_status(
    `/api/v1/compra/proceso/notificacion-resolucion-revision/obtener/${idProcesoCompra}/${idEtapaProceso}`,
    null,
    'get',
);

const descargarDocumentoNotificacionResolucionResultados = (idProcesoCompra, idEtapaProceso) => http_client_with_status(
    `/api/v1/compra/proceso/notificacion-resolucion-revision/descargar/${idProcesoCompra}/${idEtapaProceso}`,
    null,
    'get',
    {},
    { responseType: 'blob' }
);

const guardarDocumentoNotificacionResolucionResultados = (idProcesoCompra, idEtapaProceso, data) => http_client_with_status(
    `/api/v1/compra/proceso/notificacion-resolucion-revision/guardar/${idProcesoCompra}/${idEtapaProceso}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const eliminarDocumentoPresentacionRecursoApelacion = (idProcesoCompra, idDocumento) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/apelaciones/eliminar/${idProcesoCompra}/${idDocumento}`,
    null,
    'delete',
);

// Presentacion recurso de apelacion
const obtenerDocumentoPresentacionRecursoApelacion = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/obtener/${idProcesoCompra}`,
    null,
    'get',
);

const descargarDocumentoPresentacionRecursoApelacion = (idProcesoCompra, idDocumento) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/descargar/${idProcesoCompra}/${idDocumento}`,
    null,
    'get',
    {},
    { responseType: 'blob' }
);

const guardarDocumentoPresentacionRecursoApelacion = (idProcesoCompra, data) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/guardar/${idProcesoCompra}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const cargarEtapasRecursoApelacion = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/etapas/${idProcesoCompra}`,
    null,
    'get'
);

const actualizarEtapaRecursoApelacion = (idProcesoCompra, idEtapaRecursoApelacion, data) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/etapas/actualizar/${idProcesoCompra}/${idEtapaRecursoApelacion}`,
    data,
    'patch',
);

const cargarEtapaActiva = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/etapas/activa/${idProcesoCompra}`,
    null,
    'get',
);

const cargarProveedoresParaApelacion = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/proveedores/${idProcesoCompra}`,
    null,
    'get',
);

const cerrarEtapa = (idProcesoCompra, idEtapaApelacion) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/etapas/cerrar/${idProcesoCompra}/${idEtapaApelacion}`,
    null,
    'patch',
);

const cargarApelaciones = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/obtener/apelacion/${idProcesoCompra}`,
    null,
    'get',
);

const atenderApelacion = (idProcesoCompra, idApelacion, data) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/estado/${idProcesoCompra}/${idApelacion}`,
    data,
    'put',
);

const cargarApelacionesAdmitidas = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/obtener/apelacion/aprobadas/${idProcesoCompra}`,
    null,
    'get',
);

const verificarAdjudicacionProveedor = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/verificar/proveedor-ganador/${idProcesoCompra}`,
    null,
    'get',
);

const verificaProveedorConOferta = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/verificar/proveedor-con-oferta/${idProcesoCompra}`,
    null,
    'post',
);

const cargarProveedoresConOfertaProceso = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/proveedores-con-oferta/${idProcesoCompra}`,
    null,
    'get',
);

const guardarPronunciamientoRecursoApelacion = (idProcesoCompra, data) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/guardar-apelacion/pronunciamiento-partes/${idProcesoCompra}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const cargarPronunciamientosRecursoApelacion = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/listar-apelacion/pronunciamiento-partes/${idProcesoCompra}`,
    null,
    'get',
);

const eliminarPronunciamientoRecursoApelacion = (idProcesoCompra, idDocumento) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/eliminar-pronunciamiento/${idProcesoCompra}/${idDocumento}`,
    null,
    'delete',
);

// Actos de tramite
const cargarActosTramite = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/listar-apelacion/actos-tramites/${idProcesoCompra}`,
    null,
    'get',
);

const guardarActoTramite = (idProcesoCompra, data) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/guardar-apelacion/actos-tramites/${idProcesoCompra}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const eliminarActoTramite = (idProcesoCompra, idDocumento) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/eliminar-actos-tramites/${idProcesoCompra}/${idDocumento}`,
    null,
    'delete',
);

// Resolucion final
const cargarResoluciones = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/listar-apelacion/resolucion-final/${idProcesoCompra}`,
    null,
    'get',
);

const guardarResolucionFinal = (idProcesoCompra, data) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/guardar-apelacion/resolucion-final/${idProcesoCompra}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const eliminarResolucionFinal = (idProcesoCompra, idDocumento) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/eliminar-resolucion-final/${idProcesoCompra}/${idDocumento}`,
    null,
    'delete',
);

// Notificaciones de recurso de apelacion
const cargarNotificaciones = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/notificacion-resolucion-apelacion/listar/${idProcesoCompra}`,
    null,
    'get',
);

const guardarNotificacion = (idProcesoCompra, data) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/notificacion-resolucion-apelacion/guardar/${idProcesoCompra}`,
    data,
    'post',
    { 'Content-Type': 'multipart/form-data' },
);

const eliminarNotificacion = (idProcesoCompra, idDocumento) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/notificacion-resolucion-apelacion/eliminar/${idProcesoCompra}/${idDocumento}`,
    null,
    'delete',
);

// Comunes
const visualizarDocumentoEtapaResolucion = (idDocumento) => http_client_with_status(
    `/api/v1/compra/proceso/presentacion-recurso-apelacion/etapa-resolucion/visualizar/${idDocumento}`,
    null,
    'get',
    {},
    { responseType: 'blob' }
);

// Actas de resolucion de recurso de apelacion
const cargarActasRecursosApelacion = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/recursos-apelacion/actas/${idProcesoCompra}`,
    null,
    'get',
);

const visualizarActaRecursoApelacion = (idProcesoCompra, idRecursoApelacion) => http_client_with_status(
    `/api/v1/compra/proceso/recursos-apelacion/visualizar-acta/${idProcesoCompra}/${idRecursoApelacion}`,
    null,
    'get',
    {},
    { responseType: 'blob' },
);

// Siguiente etapa recurso apelacion
const cargarSiguienteEtapaRecursoApelacion = (idProcesoCompra) => http_client_with_status(
    `/api/v1/compra/proceso/recurso-apelacion/etapas/siguiente-etapa/${idProcesoCompra}`,
    null,
    'get',
)

export default {
    // Notificacion de resolucion de revision
    obtenerDocumentoNotificacionResolucionResultados,
    descargarDocumentoNotificacionResolucionResultados,
    guardarDocumentoNotificacionResolucionResultados,

    // Presentacion recurso de apelacion
    obtenerDocumentoPresentacionRecursoApelacion,
    descargarDocumentoPresentacionRecursoApelacion,
    guardarDocumentoPresentacionRecursoApelacion,
    cargarEtapasRecursoApelacion,
    actualizarEtapaRecursoApelacion,
    cargarEtapaActiva,
    cargarProveedoresParaApelacion,
    eliminarDocumentoPresentacionRecursoApelacion,
    cerrarEtapa,
    cargarApelaciones,
    atenderApelacion,

    // Resolucion recursos de apelacion
    cargarApelacionesAdmitidas,
    verificarAdjudicacionProveedor,
    verificaProveedorConOferta,
    cargarProveedoresConOfertaProceso,
    guardarPronunciamientoRecursoApelacion,
    cargarPronunciamientosRecursoApelacion,
    eliminarPronunciamientoRecursoApelacion,

    // Actos de tramite
    cargarActosTramite,
    guardarActoTramite,
    eliminarActoTramite,

    // Resolucion final
    cargarResoluciones,
    guardarResolucionFinal,
    eliminarResolucionFinal,

    // Notificaciones
    cargarNotificaciones,
    guardarNotificacion,
    eliminarNotificacion,

    // Comunes
    visualizarDocumentoEtapaResolucion,

    // Actas de resolucion de recursos de apelacion
    cargarActasRecursosApelacion,
    visualizarActaRecursoApelacion,

    // Siguiente etapa recurso apelacion
    cargarSiguienteEtapaRecursoApelacion,
};
