import { get, isNil, unset } from 'lodash';

export const convertToFormData = (data, formData = new FormData(), parentKey) => {
    if (parentKey && typeof data === 'object' && data !== null && data instanceof File) {
        formData.append(parentKey, data);
    } else {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const value = data[key];
            const formKey = parentKey ? `${parentKey}[${key}]` : key;
            
            if (isNil(value)) continue;

            if (
                typeof value === 'object' && 
                value !== null &&
                !Array.isArray(value) && 
                !(value instanceof File) &&
                !(value instanceof Date)
            ) {
                convertToFormData(value, formData, formKey);
            } else if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
    
                  if (value[i] === null || typeof value[i] !== 'object') {
                    formData.append(`${formKey}[${i}]`, value[i]);
                  } else {
                    convertToFormData(value[i], formData, `${formKey}[${i}]`);
                  }
                }
            } else if (value instanceof Date) {
                formData.append(formKey, value.toUTCString());
            } else if (value instanceof File) {
                formData.append(formKey, value);
            } else {
                formData.append(formKey, value);
            }
          }
        }
    }
    return formData;
}

export const getFilenameFromPath = (path) => {
    const index = path.lastIndexOf('/');
    return path.substring(index + 1);
}

export const getExtFromPath = (path) => {
    const index = path.lastIndexOf('.');
    return path.substring(index + 1);
};

export const obtenerNombreCompletoPersona = (persona) => {
    const {
        primer_nombre,
        segundo_nombre,
        primer_apellido,
        segundo_apellido,
        apellido_casada,
    } = persona;

    let nombre_completo = '';

    if (primer_nombre) {
        nombre_completo += primer_nombre;
    }
    if (segundo_nombre) {
        nombre_completo += ` ${segundo_nombre}`;
    }
    if (primer_apellido) {
        nombre_completo += ` ${primer_apellido}`;
    }
    if (segundo_apellido) {
        nombre_completo += ` ${segundo_apellido}`;
    }
    if (apellido_casada) {
        nombre_completo += ` ${apellido_casada}`;
    }

    return nombre_completo;
}


export const removePropsFromObjectIfNil = (object, keys) => {
    keys.forEach((key) => {
        const value = get(object, key);
        if (isNil(value)) unset(object, key);
    });

    return object;
};

export const removePropsFromObjectIfFalsy = (object, keys) => {
    keys.forEach((key) => {
        const value = get(object, key);
        if (!value) unset(object, key);
    });

    return object;
};

export const toMoneyFormat = (value, loading, options) => {
    if (loading) return 'Cargando...';

    if (!value || isNaN(value)) {
        return '$0.00';
    }

    return Intl.NumberFormat("en-US", {
        ...options,
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};

export const toFixed = (value, decimals = 2) => {
    const valorConvertir = Number.isNaN(value) ? 0 : Number(value);

    return Number(valorConvertir.toFixed(decimals));
};

export const mostrarSiNoEstaCargando = (valor, cargando) => {
    if (cargando) return 'Cargando...';

    return valor ?? '-';
};

export const mostrarPorcentaje = (valor, cargando) => {
    if (cargando) return 'Cargando...';

    return isNil(valor) ? '-' : `${valor.toFixed(2)}%`;
};
