import { http_client, http_client_with_status } from "@/plugins/http_client";

// petición GET
const getListAsuetos = async (filters) => await http_client('/api/v1/asuetos/personalizados', filters);

const postAsueto = async (body) => await http_client('/api/v1/asuetos/personalizados', body, 'post');

const deleteAsueto = async (id_asueto) => await http_client(`/api/v1/asuetos/personalizados/${id_asueto}`, {}, 'delete');

const cargarDiasNoLaborables = (params) => http_client_with_status('/api/v1/asuetos/catalogo/no-laborables', params, 'get');

export default {
    getListAsuetos,
    postAsueto,
    deleteAsueto,
    cargarDiasNoLaborables,
}
