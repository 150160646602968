const cmReporteriaRoutes = [
    {
        path: "/convenio-marco-reporteria",
        name: "convenio-marco-reporteria",
        component: () =>
            import(
                /* webpackChunkName: "creacionConvenio" */ "./reporteComprasView.vue"
            ),
    },
    {
        path: "/procesos-pendientes-contratacion",
        name: "procesos-pendientes-contratacion",
        component: () =>
            import(/* webpackChunkName: "ProcesosPublicosReporteria" */ "./reporteProcesosLibres.vue"),
    },
    {
        path: "/procesos-pendientes-contratacion-copia",
        name: "procesos-pendientes-contratacion-copia",
        component: () =>
            import(/* webpackChunkName: "ProcesosPublicosReporteria" */ "./reporteProcesosLibresCopia.vue"),
    },
    {
        path: "/procesos-en-reserva",
        name: "procesos-en-reserva",
        component: () =>
            import(/* webpackChunkName: "ProcesosEnReserva" */ "./reporteProcesoReserva.vue"),
    },
    {
        path: '/reporteria-baja-cuantia',
        name: 'reporteria-baja-cuantia',
        component: () => 
            import(
                /* webpackChunkName: "reporteBajaCuantia" */ "./reporteBajaCuantia.vue"
            ),
    },
    {
        path: '/reporteria-auditoria-interna',
        name: 'reporteria-auditoria-interna',
        component: () => 
            import(
                /* webpackChunkName: "reporteAuditoriaInterna" */ "./reporteAuditoriaInterna.vue"
            ),
    },
    {
        path: '/reporteria-modalidades-procesos',
        name: 'reporteria-modalidades-procesos',
        component: () => 
            import(
                /* webpackChunkName: "reporteAuditoriaInterna" */ "./modalidadesCompra/reporteModalidadesProcesos.vue"
            ),
    },
    {
        path: '/reporteria-compra-en-linea',
        name: 'reporteria-compra-en-linea',
        component: () => 
            import(
                /* webpackChunkName: "reporteCompraEnLinea" */ "./compraEnLinea/reporteCompraEnLinea.vue"
            ),
    },
    {
        path: '/reporteria-compra-en-linea/documentos-registro/:id_registro',
        name: 'documentos-fondo-reporteria-compra-en-linea',
        component: () => 
            import(
                /* webpackChunkName: "documentosRegistroReporteriaCompraLinea" */ "./compraEnLinea/documentosCompraEnLinea.vue"
            ),
    },

    {
        path: '/reporteria-compra-en-linea/registros/:id_fondo',
        name: 'registros-fondo-reporteria-compra-en-linea',
        component: () => 
            import(
                /* webpackChunkName: "registrosFondoReporteriaCompraEnLinea" */ "./compraEnLinea/registrosFondoCompraEnLinea.vue"
            ),
    },

    {
        path: '/reporteria-cierre-contratos',
        name: 'reporteria-cierre-contratos-institucion',
        component: () => 
            import(
                /* webpackChunkName: "reporteriaCierreContratos" */ "./reporteCierreContratos.vue"
            ),
    },
];

export default cmReporteriaRoutes;
