import { http_client_slim } from "@/plugins/http_client";

const client = http_client_slim("/api/v1/permisos-extra");

const getPermisos = (id) => client(`/${id}`);
const catalogoPermisos = () => client(`/catalogo`);
const agregarPermiso = ({ data, idUsuario, idPermiso }) =>
  client(`/agregar/${idUsuario}/${idPermiso}`, { method: "post", data });

const deletePermiso = (id) => client(`/${id}`, { method: "delete" });

export default {
  getPermisos,
  catalogoPermisos,
  agregarPermiso,
  deletePermiso,
};
