import { http_client_with_status, http_client } from "@/plugins/http_client";

const getCifradosOBS = (id_obs, params = {}) => http_client(
    `/api/v1/pac/${id_obs}`, 
    params, 
    "get",
    {},
    true,
    "",
    true,
    null,
    false,
    false,
);

const cargarProcesosCompra = (filtros) => http_client_with_status('/api/v1/refinanciamiento-compras/procesos', filtros, 'get');

const cargarMontos = (idProcesoCompra) => http_client_with_status(`/api/v1/refinanciamiento-compras/proceso/montos/${idProcesoCompra}`, null, 'get');

const cargarNecesidades = (idProcesoCompra) => http_client_with_status(`/api/v1/refinanciamiento-compras/proceso/necesidades/${idProcesoCompra}`, null, 'get');

// Manejo refinanciamientos
const buscarRefinanciamiento = (idProcesoCompra, idRefinanciento) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/buscar/${idProcesoCompra}/${idRefinanciento}`,
    null,
    'get',
);

const listarRefinanciamientosPorObs = (idProcesoCompra) => http_client_with_status(`/api/v1/refinanciamiento-compras/refinanciamientos/obs/${idProcesoCompra}`, null, 'get');

const listarRefinanciamientos = (idProcesoCompra) => http_client_with_status(`/api/v1/refinanciamiento-compras/refinanciamientos/listar/${idProcesoCompra}`, null, 'get');

const listarFondosPorRefinanciamiento = (idProcesoCompra, idRefinanciento) => 
    http_client_with_status(`/api/v1/refinanciamiento-compras/refinanciamientos/fondos/${idProcesoCompra}/${idRefinanciento}`, null, 'get');

const crearRefinanciamiento = (idProcesoCompra, data) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/crear/${idProcesoCompra}`,
    data,
    'post',
);

const agregarFondoRefinanciamiento = (idProcesoCompra, idRefinanciento, data) =>  http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/fondos/agregar/${idProcesoCompra}/${idRefinanciento}`,
    data,
    'post',
);

const eliminarRefinanciamiento = (idProcesoCompra, idRefinanciento) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/eliminar/${idRefinanciento}/${idProcesoCompra}`,
    null,
    'delete',
);

const ajustarFondoRefinanciamiento = (idProcesoCompra, idFondoRefinanciamiento, data) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/fondos/ajustar/${idProcesoCompra}/${idFondoRefinanciamiento}`,
    data,
    'patch',
);

const eliminarFondoRefinanciamiento = (idProcesoCompra, idFondoRefinanciamiento) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/fondos/eliminar/${idProcesoCompra}/${idFondoRefinanciamiento}`,
    null,
    'delete',
);

const cambiarObsRefinanciamiento = (idProcesoCompra, idRefinanciento, data) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/cambio-obs/${idProcesoCompra}/${idRefinanciento}`,
    data,
    'put'
);

const cargarInstituciones = (filtros) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/catalogo/instituciones`,
    filtros,
    'get',
); 

const cargarRefinanciamientosPorContrato = (idContrato) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/contrato/${idContrato}`,
    null,
    'get',
);

const cargarObsSegunNecesidades = (idProcesoCompra, params) => http_client_with_status(`/api/v1/refinanciamiento-compras/obs/${idProcesoCompra}`, params, 'get');

const cargarCifradosSegunObs = (idProcesoCompra, idObs, params) => 
    http_client_with_status(`/api/v1/refinanciamiento-compras/cifrados/${idProcesoCompra}/${idObs}`, params, 'get');

const verificarPermisosRefinanciamiento = (idProcesoCompra, data) => http_client_with_status(
    `/api/v1/refinanciamiento-compras/refinanciamientos/verificar-permisos/${idProcesoCompra}`,
    data,
    'post',
);

export default {
    getCifradosOBS,
    cargarProcesosCompra,
    cargarMontos,
    cargarNecesidades,

    // Manejo refinanciamientos
    buscarRefinanciamiento,
    listarRefinanciamientosPorObs,
    listarRefinanciamientos,
    listarFondosPorRefinanciamiento,
    crearRefinanciamiento,
    agregarFondoRefinanciamiento,
    eliminarRefinanciamiento,
    ajustarFondoRefinanciamiento,
    eliminarFondoRefinanciamiento,
    cambiarObsRefinanciamiento,
    cargarInstituciones,
    cargarRefinanciamientosPorContrato,
    cargarObsSegunNecesidades,
    cargarCifradosSegunObs,
    verificarPermisosRefinanciamiento,
};
