<template>
    <v-app>
        <template v-if="sesionValidada">
            <div class="d-flex align-center justify-space-between px-4 py-4" style="width: 100%;">
                <NavButton />
                <v-menu
                    offset-y
                    transition="scroll-y-transition"
                    rounded="lg"
                    style="z-index: 300"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-2" v-bind="attrs" v-on="on" elevation="" icon>
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense nav>
                        <v-list-item @click="abrirModalCambioPassword" class="my-0">
                            <div class="d-flex align-center px-4 py-4" style="gap: 16px">
                                <v-icon size="20">mdi-wrench</v-icon>
                                <span class="text-uppercase font-weight-medium text-body-2">Cambiar contraseña</span>
                            </div>
                        </v-list-item>
                        <v-list-item @click="cerrarSesion" class="my-0">
                            <div class="d-flex align-center px-4 py-4" style="gap: 16px">
                                <v-icon size="20">mdi-logout</v-icon>
                                <span class="text-uppercase font-weight-medium text-body-2">Cerrar Sesión</span>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <ModalCambioPassword :is-open.sync="modalCambioPasswordAbierta" />
            </div>
            <v-divider />
            <slot></slot>
        </template>
    </v-app>
</template>
<script>
import ModalCambioPassword from './procesos_libres/ModalCambioPassword.vue';
import { LocalStorage } from '@/utils/LocalStorage.mjs';
import NavButton from './NavButton.vue';

export default {
    components: { NavButton, ModalCambioPassword },
    data: () => ({
        sesionValidada: false,
        modalCambioPasswordAbierta: false,
    }),
    methods: {
        cerrarSesion() {
            this.sesionValidada = false;
            LocalStorage.token_procesos_libres = null;
            this.pushAppMessage({ type: 'success', message: 'Sesión cerrada.' })
            this.$router.push('/login-procesos-compra');
        },
        abrirModalCambioPassword() {
            this.modalCambioPasswordAbierta = true;
        },
    },
    beforeMount() {
        if (!LocalStorage.token_procesos_libres) {
            this.$router.push('/login-procesos-compra');
            return;
        }

        this.sesionValidada = true;
    },
}
</script>