import { interceptarErrorDeRedNuevoCliente } from "@/utils/NetworkErrorInterceptors";
import { generateApiFormatError } from "./http_client";
import { isApiErrorResponse, isBlobApiErrorResponse } from "@/utils/loadable";
import Axios from "axios";
import { LocalStorage } from "@/utils/LocalStorage.mjs";
import router from "@/router";
import Vue from "vue";

let config = {
    baseURL: process.env.VUE_APP_API_URL || "http://127.0.0.1:8000/",
};

const axiosInstance = Axios.create(config);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const response = error.response;

        if (response && response.status === 401) {
            LocalStorage.token_procesos_libres = null;
            router.replace("/login-procesos-compra");
            Vue.prototype.pushAppMessage({ type: 'error', message: 'No autenticado.' });
            throw new Error('No autenticado');
        }

        throw error;
    }
);

const validStatus = [200, 201, 204];

export const http_client_procesos_publicos = async (
    url,
    data = {},
    method = "get",
    headers = {},
    requestConfig = {},
  ) => {
    const token = localStorage.getItem("token_procesos_libres");
  
    if (token) headers.Authorization = "Bearer " + token;
  
    let config = {
      ...requestConfig,
      method,
      url,
      headers,
      validateStatus: (status) => validStatus.includes(status),
    };
  
    const propName = method === "get" ? "params" : "data";
    config[propName] = data;
  
    try {
      if (url.includes("undefined") || url.includes("null")) {
        throw new Error("Parámetros inválidos en la url");
      }
      const res = await axiosInstance(config);
      return res;
    } catch (error) {
  
      // Manejo de errores de tipo archivo
      if (error.response.data instanceof Blob) {
        const [isApiError, decoded] = await isBlobApiErrorResponse(
          error.response.data
        );
  
        if (isApiError)
          return {
            data: generateApiFormatError(
              decoded.error.message,
              decoded.error.code
            ),
          };
      }
  
      // Manejo de errores por defecto
      const fallback =
        "Su solicitud no ha podido ser procesada, vuelva a intentar. Si el error persiste, intente más tarde.";
      const errorMessage = isApiErrorResponse(error.response.data)
        ? error.response.data.error.message
        : error.response.data?.message ?? error.response.data?.description ?? fallback;
      const errorCode = isApiErrorResponse(error.response.data)
        ? error.response.data.error.code
        : undefined;
  
      const networkError = { data: generateApiFormatError(errorMessage, errorCode) };
  
      interceptarErrorDeRedNuevoCliente(networkError);
  
      return networkError;
    }
  };