import { http_client_with_status } from "@/plugins/http_client";

const cargarProcesos = (filtros) => http_client_with_status(
    `/api/v1/reporteria-modalidades-compra/procesos`,
    filtros,
    'get',
);

const cargarEstadisticasProcesos = (filtros) => http_client_with_status(
    `/api/v1/reporteria-modalidades-compra/procesos/estadisticas-procesos`,
    filtros,
    'get',
);

const cargarEstadisticasPorModalidad = (filtros) => http_client_with_status(
    `/api/v1/reporteria-modalidades-compra/estadisticas-por-modalidad`,
    filtros,
    'get',
);

export default {
    cargarProcesos,
    cargarEstadisticasProcesos,
    cargarEstadisticasPorModalidad,
};

